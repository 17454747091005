<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !_getRegionList || !_getPondList || !application,
		}"
	>
		<v-preloader
			v-if="!_getRegionList || !_getPondList || !application"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="_getRegionList && _getPondList && application">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['PowerReservoirManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.power_reservoir') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Наименование</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															v-model="application.name"
															:placeholder="$t('headers.name')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Пользователь</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.user
																? 'input--error'
																: '',
														]"
													>
														<UserAsyncList
															v-model="userValue"
															:value="userValue"
															placeholder="Выберите пользователя"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.user"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.user"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Регион</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<multiselect
															required=""
															v-model="regionValue"
															:options="formList.region"
															:preserve-search="true"
															track-by="id"
															label="name"
															placeholder="Выберите регион"
															class="multiselect__check"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.values[0].value
															}}</template>
															<template slot="noOptions">Регион не найден</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('inputs.pond_name') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<multiselect
															required=""
															v-model="pondValue"
															:options="formList.pond"
															:preserve-search="true"
															track-by="id"
															label="name"
															placeholder="Выберите водоемы"
															class="multiselect__check"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.values[0].value
															}}</template>
															<template slot="noOptions">Водоем не найден</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Файл</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-7">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.document
																? 'input--error'
																: '',
														]"
													>
														<label class="form__line--file" :class="fileDocuments.fileClass">
															<input
																type="file"
																ref="fileDocuments"
																name="fileDocuments"
																accept=".jpeg,.png,.jpg,.pdf,.doc,.docx"
																v-on:change="handleFileUpload()"
															/>
															<div class="form__file" v-if="fileDocuments.fileName">
																<div class="form__file--text">Скачать файл</div>
															</div>
															<div class="form__file" v-else>
																<div class="form__file--text">{{ $t('site.upload_file') }}</div>
																<div class="form__file--icon">
																	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.167 11.475a3.188 3.188 0 00-1.771-5.808H11.12a4.958 4.958 0 10-8.288 4.887M8.5 8.5v6.375M5.667 12.042L8.5 14.875l2.833-2.833" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</div>
															</div>
														</label>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.document"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.document"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="input__row">
														<button class="input-btn form__line--btn">
															{{ $t('button.save') }}
														</button>
													</div>

													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="errorMessage.text"
														:class="{
															'form__messages--error': errorMessage.status != 200,
															'form__messages--success': errorMessage.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ errorMessage.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'
import UserAsyncList from '@/components/catalog/UserAsyncList.vue'

export default {
	components: {
		Multiselect,
		UserAsyncList,
	},
	data() {
		return {
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			application: null,

			regionValue: null,

			pondValue: null,

			userValue: null,

			fileDocuments: {
				file: null,
				fileName: '',
				fileClass: '',
			},
		}
	},
	watch: {
		applicationsList: {
			handler() {
				this.dataItem()
			},
		},
	},
	computed: {
		...mapGetters(['_getRegionList', '_getPondList']),
		formList() {
			let pondList = []
			pondList = this._getPondList.filter((element) => {
				if (this.regionValue && this.regionValue.links) {
					return this.regionValue.links.find((selected) => {
						return element.id == selected.item_id ? true : false
					})
				}
				return false
			})

			let list = {
				region: this._getRegionList,
				pond: pondList,
			}
			return list
		},
		applicationsList() {
			const { application, _getRegionList, _getPondList } = this
			return { application, _getRegionList, _getPondList }
		},
	},

	methods: {
		...mapActions(['_fetchRegionList', '_fetchPondList']),
		dataItem() {
			if (this.application && this._getRegionList && this._getPondList) {
				this.regionValue = this._getRegionList.find((element) => {
					if (element.id == this.application.region_id) {
						return element
					}
				})
				this.pondValue = this._getPondList.find((element) => {
					if (element.id == this.application.pond_id) {
						return element
					}
				})
			}
		},
		handleFileUpload() {
			this.message.status = null
			this.message.text = null
			this.message.texts = null
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},
		onSubmit(evt) {
			this.message.status = null
			this.message.text = null
			this.message.texts = null

			const formData = new FormData(evt.target)
			if (this.regionValue) {
				formData.append('region_id', this.regionValue.id) //this.pondModel.id
			}
			if (this.pondValue) {
				formData.append('pond_id', this.pondValue.id) //this.pondModel.id
			}
			if (this.userValue) {
				formData.append('user_id', this.userValue.id) //this.pondModel.id
			}
			if (this.fileDocuments.file) {
				formData.append('document', this.fileDocuments.file)
			}
			api
				.post('power-reservoir/' + this.$route.params.id, formData)
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/power-reservoir/' + response.data.data.id,
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
		apiGetPower() {
			api
				.get('power-reservoir/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.application = response.data

						this.userValue = this.application.user
						this.fileDocuments.file = this.application.document
						this.fileDocuments.fileName = this.application.document
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	beforeMount() {
		this.apiGetPower()
		this._fetchPondList()
		this._fetchRegionList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
